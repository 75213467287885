import { cn } from "@/lib/utils.ts";
import type React from "react";

export const ZipModal = ({
	children,
	className = "",
	width = "sm:w-full",
	maxWidth = "sm:max-w-6xl",
	padding = "px-4 pt-5 sm:px-6",
	portalClassname = "",
	margin = "",
}: {
	children: React.ReactNode;
	className?: string;
	maxWidth?: string;
	padding?: string;
	width?: string;
	margin?: string;
	portalClassname?: string;
}) => {
	return (
		<div
			className={cn({
				"fixed inset-x-0 bottom-0 z-9999 px-4 sm:inset-0 sm:flex sm:items-center sm:justify-center sm:p-0": true,
				[portalClassname]: !!portalClassname,
				[margin]: !!margin,
			})}
		>
			<div className={"fixed inset-0 transition-opacity"}>
				<div className={"absolute inset-0 bg-gray-400 opacity-75"} />
			</div>

			<div
				className={cn({
					"transform overflow-hidden rounded bg-white shadow-xl transition-all": true,
					// [`px-4 pt-5 sm:w-full sm:max-w-6xl sm:px-6`]: true,
					[maxWidth]: !!maxWidth,
					[width]: !!width,
					[padding]: !!padding,
					[className]: !!className,
				})}
				role="dialog"
				aria-modal="true"
				aria-labelledby="modal-headline"
			>
				{children}
			</div>
		</div>
	);
};

export const Header = ({
	children,
	className = "",
}: {
	children: React.ReactNode;
	className?: string;
}) => (
	<div className={"mt-3 text-center sm:mt-5"}>
		<h3
			className={cn({
				"mb-4 text-2xl font-medium leading-6 text-zd-darkgray-700": true,
				[className]: !!className,
			})}
		>
			{children}
		</h3>
	</div>
);

export const Content = ({
	children,
	className = "",
}: {
	children: React.ReactNode;
	className?: string;
}) => (
	<div
		className={cn({
			"mt-2 rounded px-4 py-2 text-center text-base text-zd-darkgray-500": true,
			[className]: !!className,
		})}
	>
		{children}
	</div>
);

export const Buttons = ({
	children,
	className = "",
	justify = "justify-center",
	spacer = "space-x-4",
}: {
	children: React.ReactNode;
	className?: string;
	justify?: string;
	spacer?: string;
}) => (
	<div
		className={cn({
			"mt-5 flex w-full py-6 sm:mt-6": true,
			[justify]: !!justify,
			[spacer]: !!spacer,
			[className]: !!className,
		})}
	>
		{children}
	</div>
);

ZipModal.Header = Header;
ZipModal.Content = Content;
ZipModal.Buttons = Buttons;
